import { createStore } from 'vuex'
import { auth } from './auth.module'
import { alert } from './alert.module'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    modules: {
        auth,
        alert
    },
    plugins: [createPersistedState()]
})