import axios from 'axios'
import router from '../router'

export const auth = {
    namespaced: true,
    state: {
        user: null,
        authenticated: false
    },
    actions: {
        login({ dispatch, commit }, { username, password }) {
            const data = {
                Username: username,
                Password: password,
            };

            axios.post("/login", data).then(response => {
                if (response.data.Error) {
                    commit('loginFailure')
                    dispatch('alert/error', response.data.Error, { root: true })
                }
                else {
                    const user = {
                        Id: response.data.AuthorizationLogin.UserId,
                        DBLeadPostCode: response.data.AuthorizationLogin.DBLeadPostCode,
                        SessionId: response.data.AuthorizationLogin.SessionId
                    }
                    localStorage.setItem('user', user.SessionId)
                    commit('loginSuccess', user)
                    router.push('/');
                }
            });
        },
        logout({commit}) {
            localStorage.removeItem('user');
            commit('logout')
        }
    },
    mutations: {
        loginSuccess(state, user) {
            state.user = user
            state.authenticated = true
        },
        loginFailure(state) {
            state.user = null
            state.authenticated = false
        },
        logout(state)
        {
            state.user = null
            state.authenticated = false
        }
    },
    getters: {
        isAuthenticated: (state) => !!state.user,
        StateUser: (state) => state.user,
    },
}
