import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
    { path: '/', component: Home },
    {
        path: '/login',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/huawei',
        name: 'OltList',
        component: () => import('@/views/Huawei/OltList.vue'),
    },
    {
        path: '/huawei/olt/:id',
        name: "Olt",
        component: () => import('@/views/Huawei/Olt.vue'),
    },
    {
        path: '/huawei/olt/:id/template',
        name: "OltTemplate",
        component: () => import('@/views/Huawei/OltTemplate.vue'),
    },
    {
        path: '/huawei/olt/:id/template/:templateid/show',
        name: "OltTemplateServicePort",
        component: () => import('@/views/Huawei/OltTemplateServicePort.vue'),
    },
    {
        path: '/huawei/ont/:id',
        name: "Ont",
        component: () => import('@/views/Huawei/Ont.vue'),
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/login']
    const authRequired = !publicPages.includes(to.path)
    const loggedIn = localStorage.getItem('user')

    if (authRequired && !loggedIn)
        return next('/login')

    next()
});

export default router
