<template>
<nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
    <!-- Navbar Brand-->
    <router-link to="/" class="navbar-brand ps-3">ISPMS</router-link>
    <!-- Navbar Search-->
    <div class="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
        <AutoComplete v-model="SearchData" :suggestions="SearchResult" @complete="Search($event)" field="Description">
            <template #item="slotProps">
                <div class="list-group-item">
                    <router-link :to="`/huawei/ont/${slotProps.item.Id}`">[{{slotProps.item.OltName}}] {{slotProps.item.Description}} - {{slotProps.item.SerialOrMac}}</router-link>
                </div>
            </template>
        </AutoComplete>
    </div>
    <!-- Navbar-->
    <ul class="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="fas fa-user fa-fw"></i></a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                <!--<li><a class="dropdown-item" href="#">Settings</a></li>
                <li><a class="dropdown-item" href="#">Activity Log</a></li>
                <li><hr class="dropdown-divider" /></li>-->
                <li><a class="dropdown-item" id="test" href="#" @click="handleLogout">Logout</a></li>
            </ul>
        </li>
    </ul>
</nav>
</template>

<script>
import axios from 'axios'
import AutoComplete from 'primevue/autocomplete/AutoComplete'

export default {
    name: "Nav",
    components: {
        AutoComplete
    },
    data() {
        return {
            SearchData: null,
            SearchResult: null,
        }
    },
    methods: {
        handleLogout() {
          this.$store.dispatch("auth/logout");
          this.$router.push("/");
        },
        Search(event)
        {
            setTimeout(() => {
                const data = {
                    SessionId: localStorage.getItem("user"),
                    HuaweiOltOntSearch: {
                        Data: event.query,
                    },
                }

                axios.post("/huawei/search", data).then((result) => {
                    if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                    {
                        this.$store.dispatch('alert/error', result.data.Error, { root: true })
                        this.$store.dispatch('auth/logout')
                    }

                    if (this.isset(() => result.data.HuaweiOltOntSearch))
                        this.SearchResult = result.data.HuaweiOltOntSearch
                })
            }, 250)
        }
    },
};
</script>
