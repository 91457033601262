import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import PrimeVue from 'primevue/config'

axios.defaults.baseURL = '/api'
//axios.defaults.baseURL = process.env.VUE_APP_DB_SERV

const app = createApp(App)

const mixin = {
    methods: {
        isset (accessor) {
            try {
              return typeof accessor() !== 'undefined'
            } catch (e) {
              return false
            }
        }
    },
}

app.use(store)
app.use(router)
app.use(PrimeVue);

app.mixin(mixin)

app.mount('#app')
