<template>
<footer class="py-4 bg-light mt-auto">
    <div class="container-fluid px-4">
        <div class="d-flex align-items-center justify-content-between small">
            <div class="text-muted">Copyright &copy; 2020-2021</div>
        </div>
    </div>
</footer>
</template>

<script>
export default {};
</script>