<template>
    <div v-if="alert.message" :class="`alert ${alert.type}`">
        {{ alert.message }}
    </div>
    <div v-if="isLoggedIn">
        <Nav />
        <div id="layoutSidenav">
            <div id="layoutSidenav_nav">
                <Menu />
            </div>
            <div id="layoutSidenav_content">
                <main>
                    <div class="container-fluid px-4">
                        <router-view/>
                    </div>
                </main>
                <Footer />
            </div>
        </div>
    </div>
    <div v-else>
        <router-view/>
    </div>
</template>
<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Menu from '@/components/Menu.vue'

export default {
    name: "App",
    components: { Nav, Footer, Menu },
    computed: {
        alert() {
            return this.$store.state.alert;
        },
        isLoggedIn() {
            return this.$store.getters['auth/isAuthenticated'];
        }
    },
    watch: {
        $route() {
            this.$store.dispatch("alert/clear");
        },
    },
};
</script>
